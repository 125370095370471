.prefooter-blocks-small {
  font-weight: 400;
  background: #292E44;
  border-radius: 6px;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 30px;
}
.prefooter-blocks-small *, .prefooter-blocks-small *:hover {
  color: #fff;
  text-decoration: none;
}
.prefooter-blocks-small u, .prefooter-blocks-small u:hover {
  text-decoration: underline;
}
@media (max-width: 575.98px) {
.prefooter-blocks-small {
    padding: 1.25rem 1.25rem;
}
.prefooter-blocks-small .block {
    min-height: 0 !important;
    padding-bottom: 1rem !important;
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
}
.prefooter-blocks-small .block svg {
    margin-right: 1rem !important;
    margin-top: 0 !important;
    -webkit-transform: translateY(6px);
            transform: translateY(6px);
}
.prefooter-blocks-small .commonBlock:last-child .block {
    padding-bottom: 0 !important;
}
}
@media (min-width: 1600px) {
.prefooter-blocks-small {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
}
.prefooter-blocks-small .block-wrap {
  -webkit-box-flex: 100%;
      -ms-flex: 100%;
          flex: 100%;
}
.prefooter-blocks-small .block-wrap .block {
  margin: auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.prefooter-blocks-small .block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  font-size: 14px;
  min-height: 4rem;
  position: relative;
}
.prefooter-blocks-small .block p {
  margin-bottom: 0;
}
.prefooter-blocks-small .block small {
  display: block;
}
.prefooter-blocks-small .heading-block {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
}
.prefooter-blocks-small .heading-block p {
  background: #fff;
  color: #292E44;
  margin: 0;
  padding: 6px 14px !important;
  border-radius: 16px;
}
.prefooter-blocks-small .heading-block svg {
  width: 48px;
}
.prefooter-blocks-small .heading-block svg path {
  fill: #fff;
}
.prefooter-blocks-small .heading-block p {
  padding: 4px 0;
}
@media (min-width: 768px) and (max-width: 991.98px) {
.prefooter-blocks-small .block {
    margin: auto;
}
}
@media (min-width: 1200px) {
.prefooter-blocks-small .block {
    min-height: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -ms-flex-line-pack: center;
        align-content: center;
    height: 100%;
}
}
.prefooter-blocks-small .commonBlock {
  margin-bottom: 15px;
}
.prefooter-blocks-small .commonBlock:first-child {
  margin-top: 15px;
}
.prefooter-blocks-small .commonBlock .block {
  width: 100%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: start;
}
.prefooter-blocks-small .commonBlock .helpIcon {
  min-width: 40px;
  text-align: left;
}
.prefooter-blocks-small .commonBlock svg {
  width: 24px;
  height: auto;
}
.prefooter-blocks-small .commonBlock path {
  fill: #fff;
}
.prefooter-blocks-small .commonBlock.mobile svg {
  width: 18px;
}
.prefooter-blocks-small .orari {
  padding: 15px;
  background: #fff;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.prefooter-blocks-small .orari * {
  color: #292E44;
  font-size: 14px;
}
.prefooter-blocks-small .orari p {
  margin-bottom: 0.25rem;
}
@media (min-width: 576px) {
.prefooter-blocks-small .commonBlock {
    margin: 0 2.5%;
    max-width: 42.5%;
    margin-bottom: 30px;
}
.prefooter-blocks-small .commonBlock:first-child {
    margin-top: 0;
}
.prefooter-blocks-small .prefooter-blocks-small {
    -ms-flex-pack: distribute;
        justify-content: space-around;
}
}
@media (min-width: 768px) {
.prefooter-blocks-small {
    margin-top: 0 !important;
}
.prefooter-blocks-small .commonBlock {
    margin: 0 2.5%;
    max-width: 20%;
    margin-bottom: 30px;
}
.prefooter-blocks-small .faq {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
}
.prefooter-blocks-small .chat {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
}
.prefooter-blocks-small .whatsapp {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
}
.prefooter-blocks-small .mail {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
}
.prefooter-blocks-small .orari {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
}
.prefooter-blocks-small .phone {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
}
.prefooter-blocks-small .mobile {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
}
.prefooter-blocks-small .video {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
}
}
@media (min-width: 1600px) {
.prefooter-blocks-small .commonBloxk {
    max-width: 20%;
}
.prefooter-blocks-small .block {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
}
.prefooter-blocks-small .block p {
    font-size: 13px;
}
}